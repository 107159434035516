<script setup lang="ts">
import Icon from '@design-system/components/Icon/Icon.vue'

defineProps({
  toggleable: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['toggle'])

const route = useRoute()
const localeRoute = useLocaleRoute()
const localePath = useLocalePath()

const { locale } = useI18n()
const isLOSHealth = computed(() => {
  const localizedRoute = localeRoute('/health', locale.value)
  const path = localizedRoute != null ? localizedRoute.path : '/'
  return route.path.startsWith(path)
})
const isLOSOps = computed(() => {
  const localizedRoute = localeRoute('/ops', locale.value)
  const path = localizedRoute != null ? localizedRoute.path : '/'
  return route.path.startsWith(path)
})
const homeLink = computed(() => (isLOSHealth.value ? localePath('/health') : localePath('/ops')))

const collapsed = ref(false)
const isCollapsed = computed(() => Boolean(collapsed.value))
const isExpanded = computed(() => Boolean(!collapsed.value))
const onToggle = () => {
  collapsed.value = !collapsed.value
  emit('toggle', collapsed.value)
}

// PROFILE
const { user, logout } = useAuth()
</script>

<template>
  <nav class="border-b-customers flex h-[60px] items-center justify-between border-b bg-white px-6 py-2.5">
    <div class="flex items-center">
      <div
        v-if="toggleable"
        class="hidden transition-all md:ml-3 md:block"
        :class="{
          'md:mr-16': isCollapsed,
          'md:mr-[192px]': isExpanded,
        }"
      >
        <button class="hover:text-roxo-liti-500 relative h-10 w-10 text-gray-500 focus:outline-none" @click="onToggle">
          <span class="sr-only">Open main menu</span>
          <div class="absolute left-1/2 top-1/2 block w-5 -translate-x-1/2 -translate-y-1/2 transform">
            <span
              aria-hidden="true"
              class="absolute block h-0.5 w-5 -translate-y-1.5 transform rounded bg-current transition ease-in-out"
            />
            <span
              aria-hidden="true"
              class="absolute block h-0.5 w-5 transform rounded bg-current transition ease-in-out"
            />
            <span
              aria-hidden="true"
              class="absolute block h-0.5 w-5 translate-y-1.5 transform rounded bg-current transition ease-in-out"
            />
          </div>
        </button>
      </div>

      <NuxtLink :to="homeLink" class="flex items-center">
        <Icon icon="liti-logo-3" class="text-orange-500" :size="36" />
      </NuxtLink>

      <LDropdown>
        <template #button>
          <button
            type="button"
            class="ml-2 flex h-10 min-w-[150px] items-center justify-between rounded-full pl-4 pr-2 text-lg transition hover:bg-gray-100"
            aria-expanded="false"
            data-dropdown-toggle="user-dropdown"
            data-dropdown-placement="bottom"
          >
            <span class="sr-only">Open los menu</span>
            <div>
              <span v-if="isLOSHealth">{{ $t('nav.losDropdown.health') }}</span>
              <span v-else-if="isLOSOps">{{ $t('nav.losDropdown.ops') }}</span>
            </div>

            <Icon icon="chevron-down" class="text-purple-900" />
          </button>
        </template>

        <template #menu>
          <LDropdownSection>
            <LDropdownItem v-if="$can('read', 'Health')" :to="localePath('/health')">LOS Health</LDropdownItem>
            <LDropdownItem v-if="$can('read', 'OPS')" :to="localePath('/ops')">LOS Ops</LDropdownItem>
          </LDropdownSection>
        </template>
      </LDropdown>
    </div>

    <div class="flex items-center">
      <!--      <div class="mr-4">-->
      <!--        <FormKit-->
      <!--          v-model="$colorMode.preference"-->
      <!--          type="select"-->
      <!--          :options="{-->
      <!--            system: 'System',-->
      <!--            light: 'Light',-->
      <!--            dark: 'Dark',-->
      <!--          }"-->
      <!--        />-->
      <!--      </div>-->

      <LDropdown>
        <template #button>
          <button
            type="button"
            class="flex items-center rounded-full text-lg transition hover:bg-gray-100"
            aria-expanded="false"
            data-dropdown-toggle="user-dropdown"
            data-dropdown-placement="bottom"
          >
            <span class="sr-only">Open user menu</span>
            <LAvatar :username="user.fullName" :src="user.picture" :size="36" />
            <span class="ml-4 hidden sm:flex">{{ user.firstName }} | {{ localizePosition(user.position) }}</span>
            <Icon icon="chevron-down" class="ml-4 hidden text-purple-900 sm:inline-block" />
          </button>
        </template>

        <template #menu>
          <LDropdownSection>
            <LDropdownItem>{{ $t('nav.profileDropdown.myProfile') }}</LDropdownItem>
            <LDropdownItem>{{ $t('nav.profileDropdown.changePassword') }}</LDropdownItem>
          </LDropdownSection>
          <LDropdownSection>
            <LDropdownItem @click="logout">{{ $t('nav.profileDropdown.logout') }}</LDropdownItem>
          </LDropdownSection>
        </template>
      </LDropdown>
    </div>
  </nav>
</template>
